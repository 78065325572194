import React from 'react'
import Layout from "../components/layout";
import Favicon from "../images/favicon.svg";
import Seo from "../components/seo";
const OurTeachersPage = () => {
    return (
        <Layout>
            <Seo title="Our Teachers" url="https://kaladhwani.com/our-teachers" image={Favicon} />
            <div className="lg:h-400px md:h-637px h-400px w-full flex justify-center items-center ">
                <h2 className="text-black font-bold lg:text-6xl md:text-3xl text-3xl text-center">COMING SOON</h2>
            </div>
        </Layout>
    )
}
export default OurTeachersPage;
